import React, { useState, createContext } from 'react'

export const miniPlayerContext = createContext()

export const MiniPlayerProvider = ({ children }) => {

    const [miniPlayerState, setminiPlayerState] = useState({
        showVideoAtBottom: false,
        activeSlideFromMP: 0,
        isAutoMiniPlayer: false,
    })

    return (
        <miniPlayerContext.Provider value={[miniPlayerState, setminiPlayerState]}>
            {children}
        </miniPlayerContext.Provider>
    )
};
