import React, { useState, useEffect, useRef, useContext } from "react"
import { miniPlayerContext } from "../../context/miniPlayer"
import { livePlayerContext } from "../../context/livePlayer"
import { PLAYER_EVENT_PAUSE } from "../../utils/constants"

const MiniPlayer = () => {
  const [isDragging, setIsDragging] = useState(false)
  const [position, setPosition] = useState({ top: 20, left: 20 })
  const miniPlayerRef = useRef(null)
  const [miniPlayerState, setminiPlayerState] = useContext(miniPlayerContext)
  const [livePlayerState, setlivePlayerState] = useContext(livePlayerContext)
  const [closeBtnState, setCloseBtnState] = useState(true)

  const miniplayerClose = () => {
    const player = document.getElementById("live-stream-player")
    if (livePlayerState?.menuTitle === "Live Stream") {
      const liveStreamTabObserver = new MutationObserver(() => {
        let livePagePlayerEle = document.getElementById("live-page-player")
        if (livePagePlayerEle) {
          livePagePlayerEle.appendChild(player)
          liveStreamTabObserver.disconnect()
        }
      })
      liveStreamTabObserver.observe(document.body, {
        childList: true,
        subtree: true,
      })
    } else {
      const otherTabObserver = new MutationObserver(() => {
        let layoutPlayerEle = document.getElementById("layout-player")
        if (layoutPlayerEle) {
          layoutPlayerEle.appendChild(player)
          otherTabObserver.disconnect()
        }
      })
      otherTabObserver.observe(document.body, {
        childList: true,
        subtree: true,
      })
      var videoElement = document.querySelector('video.jw-video');
      videoElement.pause();
      setlivePlayerState({ ...livePlayerState, eventType: PLAYER_EVENT_PAUSE })
    }
    setminiPlayerState({
      ...miniPlayerState,
      showVideoAtBottom: false,
      isAutoMiniPlayer: false,
    })
  }

  // To handle mouse down event for dragging
  const onMouseDown = e => {
    setIsDragging(true)
    e.preventDefault()
  }

  // To handle mouse move event during dragging
  const onMouseMove = e => {
    if (!isDragging) return

    const { clientX, clientY } = e

    // Get the dimensions of the window and mini player
    const playerWidth = miniPlayerRef.current.offsetWidth
    const playerHeight = miniPlayerRef.current.offsetHeight
    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight

    // Calculate new position but constrain it within the window boundaries
    let newLeft = clientX - playerWidth / 2
    let newTop = clientY - playerHeight / 2

    // Ensure mini-player doesn't go out of bounds
    if (newLeft < 0) newLeft = 0
    if (newTop < 0) newTop = 0
    if (newLeft + playerWidth > windowWidth) newLeft = windowWidth - playerWidth
    if (newTop + playerHeight > windowHeight)
      newTop = windowHeight - playerHeight

    setPosition({ top: newTop, left: newLeft })
  }

  // To stop dragging when mouse is released
  const onMouseUp = () => {
    setIsDragging(false)
  }

  // Add event listeners for mouse move and mouse up on component mount
  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mousemove", onMouseMove)
      document.addEventListener("mouseup", onMouseUp)
    } else {
      document.removeEventListener("mousemove", onMouseMove)
      document.removeEventListener("mouseup", onMouseUp)
    }

    return () => {
      document.removeEventListener("mousemove", onMouseMove)
      document.removeEventListener("mouseup", onMouseUp)
    }
  }, [isDragging])

  if (!miniPlayerState.showVideoAtBottom) return null // Don't render the player if it's closed

  return (
    <div
      ref={miniPlayerRef}
      id="mini-player-wrapper"
      className=""
      style={{
        top: `${position.top}px`,
        left: `${position.left}px`,
        cursor: isDragging ? "grabbing" : "grab",
      }}
      onMouseDown={onMouseDown}
      onMouseEnter={() => setCloseBtnState(false)}
      onMouseLeave={() => setCloseBtnState(true)}
    >
      <div className="mini-player-container" id="mini-player">
        {!closeBtnState && (
          <button className="mini-player-close-btn" onClick={miniplayerClose}>
            X
          </button>
        )}
      </div>
    </div>
  )
}

export default MiniPlayer
