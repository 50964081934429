/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
/* global _paq */

import React, { useState, useEffect, useContext } from "react"
import { useLocation } from '@reach/router';
import PropTypes from "prop-types"
import { Link } from "gatsby"
import "typeface-work-sans"
import Header from "./header"
import "../styles/main.scss"
import NavBar from "./navBar"
import {
  TOKEN_EXPIRED,
  PLAYER_EVENT_PLAY,
  PLAYER_EVENT_PAUSE,
  PRESENTATION_STATUS_LIVE,
} from "../utils/constants"
import { isLoggedIn, clearClientData } from "../services/auth"
import { getNestedObjectValue } from "../utils/global"
import Notification from "./shared/notification"
import { miniPlayerContext } from "../context/miniPlayer"
import { livePlayerContext } from "../context/livePlayer"
import { onDemandPlayerContext } from "../context/onDemandPlayer"

import { playerEvent } from "../utils/analytics"
import { SocketContext } from "../context/socket";
import { handleRemoveLiveViewer } from '../utils/socket/socketOperations';
import { isMatomoEnabled } from "../data/global"
import LivePlayer from "./layout/LivePlayer"

const Layout = ({ children }) => {
  const location = useLocation();
  const [miniPlayerState,setminiPlayerState] = useContext(miniPlayerContext);
  const [livePlayerState,setlivePlayerState] = useContext(livePlayerContext);
  const [onDemandPlayerState] = useContext(onDemandPlayerContext);
  const [eventTitle, setEventTitle] = useState("")
  const [notification, setNotification] = useState("");
  const [notificationTime, setNotificationTime] = useState("");
  const [liveStream, setLiveStream] = useState(false);
  const [eventLiveStatus, setEventLiveStatus] = useState(null)

  const { socket, isConnected } = useContext(SocketContext);

  const getLiveStream = async () => {
    let response = await fetch(
      `${process.env.API_URL}/${process.env.EVENT_ID}/live`
    )
    response = await response.json()
    if (response && response.status === 200) {
      setlivePlayerState({ ...livePlayerState, data: response.result, isLive: true, livePageLoading: false })
      if(location && location.pathname === "/live"){
        const playerObserver = new MutationObserver(() => {
          const player = document.getElementById("live-stream-player");
          if (player) {
            const livePagePlayerEle = document.getElementById("live-page-player");
            if (livePagePlayerEle) {
              livePagePlayerEle.appendChild(player);
              playerObserver.disconnect();
            }
          }
        });
    
        playerObserver.observe(document.body, { childList: true, subtree: true });
      }
      if (response.result?.slides?.length) {
        setminiPlayerState({
          ...miniPlayerState,
          activeSlideFromMP: response.result.activeSlide || response.result.slides[0]["_id"]
        });
      }
    }
    if (response.status !== 200 && response.error === TOKEN_EXPIRED) {
      clearClientData()
    }
  }

  const fetchEventDetails = async () => {
    let response = await fetch(
      `${process.env.API_URL}/${process.env.EVENT_ID}/getEventDetails`
    )
    let data = await response.json()
    let event = getNestedObjectValue(data, "result.event")
    let eventTitle = event && event.title ? event.title : ""
    if (event && event.rocketChat) {
      window.localStorage.hasNetworking = Boolean(event.rocketChat.hasNetworking);
      window.localStorage.isNetworkingEnabled = Boolean(event.rocketChat.isNetworkingEnabled);
    } else {
      window.localStorage.hasNetworking = false;
      window.localStorage.isNetworkingEnabled = false;
    }
    setEventTitle(eventTitle)
  }

  const getDataJson = async () => {
    let random = Math.random()
    let response = await fetch(`/data.json?q=${random}`)
    if (response && response.status === 200) {
      response = await response.json()
      if (response.livewebcast !== "") {
        setLiveStream(true);
      } else {
        setminiPlayerState({ ...miniPlayerState, showVideoAtBottom: false, isAutoMiniPlayer: false }); 
        setLiveStream(false)
      }

      //Checking notifications
      if (response.broadcast && response.broadcast.message && response.broadcast.date) {
        setNotification(response.broadcast.message);
        setNotificationTime(response.broadcast.date);
      } else {
        setNotification("");
        setNotificationTime("");
      }

      if (response.livewebcast != eventLiveStatus) {
        setEventLiveStatus(response.livewebcast)
        if (response.livewebcast) {
          setlivePlayerState({...livePlayerState,livePageLoading: true})
          getLiveStream()
        } else {
          setlivePlayerState({...livePlayerState,data: null,isLive: false,livePageLoading: false})
          setminiPlayerState({ ...miniPlayerState, showVideoAtBottom: false, isAutoMiniPlayer: false }); 
        }
      }
    } else {
      setminiPlayerState({ ...miniPlayerState, showVideoAtBottom: false, isAutoMiniPlayer: false }); 
      if (response.status !== 200 && response.error === TOKEN_EXPIRED) {
        clearClientData()
      }
    }
  }

  if (isLoggedIn() && window?.location?.pathname) {
    const path = window.location.pathname;

    if (!path.includes("live") && livePlayerState?.eventType === PLAYER_EVENT_PLAY) {
      pausePlayer(livePlayerState);
    }

    // if (!path.includes("live") && !path.includes("resources")  && miniPlayerState?.eventType === PLAYER_EVENT_PLAY) {
    //   pausePlayer(miniPlayerState);
    // }

    if (!path.includes("resources") && onDemandPlayerState?.eventType === PLAYER_EVENT_PLAY) {
      pausePlayer(onDemandPlayerState);
    }
  }

  function pausePlayer(playerState) {
    playerEvent({
      eventType: PLAYER_EVENT_PAUSE,
      presentationId: playerState.presentationId,
      status: playerState.status,
    });
    if (isConnected) {
      handleRemoveLiveViewer(socket, playerState.presentationId)
    }
  }

  useEffect(() => {
    fetchEventDetails();
    if(location && location.pathname === "/live"){
      setlivePlayerState({ ...livePlayerState, menuTitle: "Live Stream" })
    }
  }, [])

  useEffect(() => { 
    getDataJson()
    const checkDataJsonInterval = setInterval(() => {
      getDataJson()
    }, 5000)

    return () => clearInterval(checkDataJsonInterval)
  }, [notification, notificationTime,eventLiveStatus, livePlayerState, miniPlayerState])

  return (
    <>    
      <Header siteTitle={eventTitle} />
      <div className="main-wrapper d-sm-flex">
        {isLoggedIn() && <NavBar liveStream={liveStream} />}
        <div className="content-wrapper d-sm-flex xs-between xs-column background-color-secondary-1">

          {isLoggedIn() && <Notification message={notification} time={notificationTime} />}

          {livePlayerState?.isLive && !livePlayerState?.livePageLoading && livePlayerState?.data &&
            <div style={{ display: 'none', width: '300px', height: '200px' }} id="layout-player">
              <LivePlayer />
            </div>
          }

          <main className="main pb-0 d-xs-flex xs-row height-full">
            {children}
          </main>

          <footer className="footer-block background-color-secondary-2">
            <div className="container-fluid">
              <ul className="footer-content d-sm-flex xs-end xs-middle width-full pl-0 mb-0 pt-15 pb-15">
                <li className="mr-30 mb-xs-20">
                  <Link
                    className="font-secondary-regular color-monochrome-1"
                    to="/accessibility"
                  >
                    Accessibility
                  </Link>
                </li>
                <li className="mr-30 mb-xs-20">
                  <Link
                    className="font-secondary-regular color-monochrome-1"
                    to="/terms-of-use"
                  >
                    Terms of use
                  </Link>
                </li>
                <li>
                  <Link
                    className="font-secondary-regular color-monochrome-1"
                    to="/cookie-policy"
                  >
                    Cookies
                  </Link>
                </li>
              </ul>
            </div>
          </footer>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default React.memo(Layout)
