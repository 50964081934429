/* globals ROUTES */
import React, { useState, useEffect, useContext } from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes, faBars } from "@fortawesome/free-solid-svg-icons"
import { miniPlayerContext } from "../context/miniPlayer"
import { livePlayerContext } from "../context/livePlayer"
import { PLAYER_EVENT_PLAY } from "../utils/constants"

const NavBar = (props) => {
  const [miniPlayerState, setminiPlayerState] = useContext(miniPlayerContext);
  const [livePlayerState, setlivePlayerState] = useContext(livePlayerContext)

  const { liveStream } = props
  // Menu state
  var sidemenu = Object.values(ROUTES).filter(
    menulink => menulink.showOnSidePanel
  )
  const [menuState, setMenuState] = useState("visible")
  const handleMenuState = () => {
    setMenuState(menuState === "visible" ? "hidden" : "visible")
  }

  // Menu title
  const [menuTitle, setMenuTitle] = useState("Menu")
  const updateActiveMenu = e => {
    const currentMenu = e.currentTarget.querySelector(".text").innerHTML;
    if (livePlayerState?.data && !livePlayerState?.livePageLoading) {
      if (currentMenu === "Live Stream" && miniPlayerState?.showVideoAtBottom && miniPlayerState.isAutoMiniPlayer) {
        const player = document.getElementById("live-stream-player");
        const observer = new MutationObserver(() => {
          const livePagePlayerEle = document.getElementById("live-page-player");
          if (livePagePlayerEle) {
            livePagePlayerEle.appendChild(player);
            setminiPlayerState({ ...miniPlayerState, showVideoAtBottom: false })
            observer.disconnect();
          }
        });
        observer.observe(document.body, { childList: true, subtree: true });
      }
      else if (livePlayerState?.eventType === PLAYER_EVENT_PLAY && !miniPlayerState?.showVideoAtBottom && currentMenu !== "Live Stream") {
        setminiPlayerState({ ...miniPlayerState, showVideoAtBottom: true, isAutoMiniPlayer: true });
        const player = document.getElementById("live-stream-player");
        const observer = new MutationObserver(() => {
          const miniPlayerEle = document.getElementById("mini-player");
          if (miniPlayerEle) {
            miniPlayerEle.appendChild(player);
            observer.disconnect();
          }
        });
        observer.observe(document.body, { childList: true, subtree: true });
      }
      else if (!miniPlayerState.showVideoAtBottom && currentMenu !== "Live Stream") {
        const player = document.getElementById("live-stream-player");
        const observer = new MutationObserver(() => {
          let layoutPlayerEle = document.getElementById("layout-player");
          if (layoutPlayerEle) {
            layoutPlayerEle.appendChild(player);
            observer.disconnect();
          }
        });
        observer.observe(document.body, { childList: true, subtree: true });
      }
      else if (currentMenu === "Live Stream" && !miniPlayerState?.showVideoAtBottom && !miniPlayerState.isAutoMiniPlayer) {
        const playerObserver = new MutationObserver(() => {
          const player = document.getElementById("live-stream-player");
          if (player) {
            const livePagePlayerEle = document.getElementById("live-page-player");
            if (livePagePlayerEle) {
              livePagePlayerEle.appendChild(player);
              playerObserver.disconnect();
            }
          }
        });

        playerObserver.observe(document.body, { childList: true, subtree: true });
      }
    }
    setMenuTitle(currentMenu)
    setlivePlayerState({ ...livePlayerState, menuTitle: currentMenu })
    // handleMenuState()
  }
  const getActiveMenuTitle = () => {
    if (document.querySelector(".sidenav .active .text")) {
      setMenuTitle(document.querySelector(".sidenav .active .text").innerHTML)
      setlivePlayerState({ ...livePlayerState, menuTitle: document.querySelector(".sidenav .active .text").innerHTML })
    }
  }
  useEffect(() => {
    getActiveMenuTitle()
  }, [])

  // Responsivness
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const mobileBrakePoint = 767
  useEffect(() => {
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth))
    // eslint-disable-next-line no-unused-expressions
    windowWidth < mobileBrakePoint
      ? setMenuState("hidden")
      : setMenuState("visible")
  }, [windowWidth])

  return (
    <aside className="sidenav background-color-monochrome-1 pt-20 pt-xs-0">
      <nav>
        <ul className="sidenav-content pl-0 mb-0 text-size-medium">
          <li
            className="sidenav-list d-sm-none"
            onClick={handleMenuState}
            role="menuitem"
            key={"mobilemenu"}
          >
            <span className="text-size-medium color-primary-1 d-xs-flex xs-between pr-20 pt-15 pb-15 pl-5 pl-xs-20 bb-1 border-color-secondary-1">
              <span className="text font-primary-medium text-size-medium">
                {menuTitle}
              </span>
              <FontAwesomeIcon
                icon={menuState === "visible" ? faTimes : faBars}
              />
            </span>
          </li>
          {menuState === "visible" && (
            <>
              {sidemenu.map(menulink => {
                if (
                  (menulink.isLiveStream !== true && (menulink.isNetworking !== true ||
                    (window.localStorage.hasNetworking === "true" &&
                      window.localStorage.isNetworkingEnabled !== "false"))) ||
                  (menulink.isLiveStream === true && liveStream)
                ) {
                  return (
                    <li
                      key={menulink.path}
                      className="sidenav-list"
                      onClick={updateActiveMenu}
                      role="menuitem"
                    >
                      <Link
                        className="d-xs-flex xs-column color-primary-1 pr-5 pt-15 pb-15 pl-5 pl-xs-20"
                        to={menulink.path}
                        activeClassName="active"
                      >
                        <span className="sidenav-icon color-primary-2 mb-5 d-xs-none d-sm-block">
                          <FontAwesomeIcon icon={menulink.icon} />
                        </span>

                        <span className="text font-primary-medium text-size-medium _a_nav">
                          {menulink.menuTitle}
                        </span>
                      </Link>
                    </li>
                  )
                } else if (
                  menulink.isNetworking === true &&
                  window.localStorage.hasNetworking === "true" &&
                  window.localStorage.isNetworkingEnabled === "false"
                ) {
                  return (
                    <li
                      key={menulink.name}
                      className="sidenav-list"
                      role="menuitem"
                    >
                      <Link
                        className="d-xs-flex xs-column color-primary-1 pr-5 pt-15 pb-15 pl-5 pl-xs-20 disabled"
                        activeClassName="active"
                      >
                        <span className="sidenav-icon color-primary-2 mb-5 d-xs-none d-sm-block">
                          <FontAwesomeIcon icon={menulink.icon} />
                        </span>

                        <span className="text font-primary-medium text-size-medium _a_nav">
                          {menulink.menuTitle}
                        </span>
                      </Link>
                    </li>
                  )
                } else {
                  return ""
                }
              })}
            </>
          )}
        </ul>
      </nav>
    </aside>
  )
}

export default React.memo(NavBar)